body{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}
.nav-link {
  color: white;
  
}
#menubar{
  background-color: rgb(48, 107, 48);
}
.text{
  padding:25px;
}
.img{
  width: 100%;
  
}
.logo{
  width: 20%;
  border-radius: 50%;
}
.head{
  color: blue;
}
.product{
  padding: 25px;
}
.contactpage{
  text-align: left;
  padding: 25px;
}
#fotter{
  background-color: black;
  color: white;
  padding: 10px;
  display: flex;
  flex-direction: row;
  
}
.vermi{
  width: 75%;
}




@media screen  and  (max-width:767px){
  #fotter{
    padding:5px ;
    flex-direction: column;
    
  }
  #logos{
    width: 50%;
    border-radius: 50%;
  }
  
}